.main-root {
    padding: 50px;
    height: 130vh;
}

.main-container {
    height: 100%
}

.main-menu {
    /* background-color: brown; */
    height: 100%;
}

.main-content {
    /* background-color: cyan; */
    height: 100%;
}

.menu-button {
    width: 100%;
}

.main-paper {
    padding: 10px;
    height: 90vh;
    min-height: 100%;
}

.main-chart{
    min-height: 65vh;
}