.main-root2 {
    padding: 50px;
    height: 20vh;
}

.main-container2 {
    height: 100%
}

.main-menu2 {
    /* background-color: brown; */
    height: 100%;
}

.main-content2 {
    /* background-color: cyan; */
    height: 100%;
}

.menu-button2 {
    width: 100%
}

.main-paper2 {
    padding: 10px;
    height: 80vh;
    min-height: 100%;
}

.main-chart2{
    min-height: 65vh;
}