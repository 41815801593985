.root {
    display: flex;
}

.appBar {
    z-Index: 3;
}

.content {
    flex-grow: 1;
    height: 100%;
}

.toolbar {
    height: 64px;
}

.account {
    margin-left: auto;
    margin-right: 0;
    z-index: 2;
}

.accountDropdown {
    z-index: 2;
}

.menuButton {
    margin-left: auto;
    align-self: center;
    margin-right: -15px;
}

.dhiLogo {
    width: 2rem;
    height: auto;
    margin-left: 0.5rem;
}

.Tab {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
