.inputField {
    margin-bottom: 3rem;
}

.submitButton {
    align-self: start;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
}